<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="1">
      <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2">Наименование:</span>
          <remote-search-input
            v-model="filterModel.nameFilter"
            searchUrl="/api/transformerWindings/SearchByName"
          />
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2">Трансформатор:</span>
          <remote-search-input
            v-model="filterModel.transformerId"
            searchUrl="/api/transformers/SearchByName"
            return-prop="id"
            :allow-create=false
            :multiple=true
          />
        </el-col>
        <el-col :span="8" class="ml-2 filter--container">
          <span class="mr-2">Функция РЗА:</span>
          <remote-search-input
            v-model="filterModel.secondEquipmentId"
            searchUrl="/api/secondEquipments/SearchByName"
            return-prop="id"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';

export default {
  name: 'TransformerWindingsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: ['1']
    };
  },
  methods: {
    handleChange(val) {
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    }
  }
};
</script>

<style scoped>

</style>
