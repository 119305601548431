<template>
  <div>
    <div>
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div>
      <el-table
        v-loading="loading"
        style="width: 100%"
        :height="tableHeight"
        :data="transformerWindings"
        :default-sort = "{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange">
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          width="250">
        </el-table-column>
        <el-table-column
          prop="transformerName"
          label="Трансформатор тока / напряжения"
          sortable="custom"
          width="250">
        </el-table-column>
        <el-table-column
          prop="secondEquipmentsName"
          label="Функция РЗА"
          sortable="custom"
          min-width="20">
        </el-table-column>
        <el-table-column
          prop="connectionKindName"
          label="Тип соединения обмотки ТН"
          sortable="custom"
          width="200">
        </el-table-column>
        <el-table-column     
          prop="nominalRatio"   
          label="Коэффициент трансформации ТТ/ТН"      
          sortable="custom"   
          width="200">
          <template
            slot="header">
            <el-popover
              ref="nominalRatioPop"
              placement="top"
              width="200"
              trigger="hover">
              <span>
              Коэффициент трансформации ТТ/ТН
              </span>
            </el-popover>
            <span>Коэф. трансформации ТТ\ТН <i
              v-popover:nominalRatioPop
              class="el-icon-info
              text-blue" />
            </span>
          </template>

        </el-table-column>
        <el-table-column
          label="Вторичное линейное номинальное напряжение ТН"
          prop="nominalVoltagePop"   
          sortable="custom"
          width="225">
          <template
            slot="header">
            <el-popover
              ref="nominalVoltagePop"
              placement="top"
              width="250"
              trigger="hover">
              <span>
              Вторичное линейное номинальное напряжение ТН
              </span>
            </el-popover>
            <span>Вторич. линейное номин. напряжение ТН <i
              v-popover:nominalVoltagePop
              class="el-icon-info
              text-blue" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import transformerWindingsApi from '@/api/nsi/transformerWindings';
import pagination from '@/components/Ui/Pagination';
import tableResize from '@/mixins/tableResize.js';

export default {
  name: 'TransformerWindingsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [tableResize],
  components: { pagination },
  data() {
    return {
      loading: false,
      transformerWindings: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getTransformerWindings();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getTransformerWindings();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getTransformerWindings() {
      this.loading = true;
      const res = await transformerWindingsApi.getTransformerWindingsWithPagination(
          this.pageNumber,
          this.pageSize,
          this.filterModel.nameFilter,
          this.filterModel.transformerId,
          this.filterModel.secondEquipmentId,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        this.transformerWindings = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getTransformerWindings();
    },
    async handleCurrentChange() {
      await this.getTransformerWindings();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getTransformerWindings();
    },
  }
};
</script>

<style scoped>

</style>
